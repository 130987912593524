import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminNavigationComponent } from '../../../../../shared/admin_header/admin-navigation/admin-navigation.component';

@Component({
  selector: 'gitex-lead-web-main',
  standalone: true,
  imports: [AdminNavigationComponent, RouterModule],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {}
