import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { assets } from '../../../core/constants/assets.const';
import { AuthService } from '../../../services/adminauth.service';
import { SpinnerService } from '../../../services/spinner.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonResponse } from '../../../models/common.model';
import { responseStatus } from '../../../core/constants/responseStatus.const';
import { environment } from '../../../../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'gitex-lead-web-admin-navigation',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './admin-navigation.component.html',
  styleUrl: './admin-navigation.component.scss'
})
export class AdminNavigationComponent {
  private destroy: Subject<boolean> = new Subject<boolean>();
  assetsConst = assets;
  constructor(
    private router: Router,
    private _authService: AuthService,
    private spinnerService: SpinnerService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}
  onDashboardClick(): void {
    this.router.navigate(['/admin/pages/dashboard']);
  }
  onLeadClick(): void {
    this.router.navigate(['/admin/pages/ManageLead']);
  }
  onteamClick(): void {
    this.router.navigate(['/admin/pages/OurTeam']);
  }

  logout(): void {
    this._authService.logoutAlert().then((res: any) => {
      if (
        Object.prototype.hasOwnProperty.call(res, 'value') &&
        res.isConfirmed === true
      ) {
        this.spinnerService.addToLoader('logout');
        this._authService
          .logout()
          .pipe(takeUntil(this.destroy))
          .subscribe({
            next: (res: CommonResponse) => {
              if (res.statusCode === responseStatus.SUCCESS) {
                if (isPlatformBrowser(this.platformId)) {
                  localStorage.removeItem(environment.storageKey);
                }
                this.spinnerService.removeFromLoader('logout');
                this.router.navigate(['/admin/auth']);
              }
            },
            error: () => {
              this.spinnerService.removeFromLoader('logout');
            }
          });
      }
    });
  }
}
