<header>
  <div class="nav_top">
    <figure class="mn_lgoo"><img [src]="assetsConst.logo" alt="Logo" /></figure>
    <div class="nav_bttm">
      <ul class="nv_lstng">
        <li class="lst_inr" type="link" (click)="onDashboardClick()">
          {{'Dashboard'|translate}}
        </li>
        <li class="lst_inr" type="link" (click)="onLeadClick()">Leads</li>
        <li class="lst_inr" type="link" routerLink="/admin/pages/OurTeam">{{'OurTeam'|translate}}</li>
      </ul>
    </div>
    <div class="rt_nv">
      <ul class="top_mn">
        <li class="usr_img">
          <figure><img [src]="assetsConst.profile" alt="User" /></figure>
        </li>
        <li class="logut_img pointer" (click)="logout()">
          <figure><img [src]="assetsConst.logout" alt="Image" /></figure>
        </li>
      </ul>
    </div>
  </div>
</header>
